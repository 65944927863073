// @import url("https://fonts.googleapis.com/css2?family=Baloo+2&family=Hubballi&family=Nunito:wght@200&family=Roboto:ital,wght@1,100;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Krub:wght@500&family=Nunito&display=swap");

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Krub", sans-serif !important;
  overflow: hidden;
}

// aside.ant-layout-sider.ant-layout-sider-dark {
//   flex: 0 0 250px !important;
//   max-width: 250px !important;
//   min-width: 250px !important;
//   width: 250px !important;
// }

// aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-zero-width.sidebar {
//   flex: 0 0 0 !important;
//   max-width: 0 !important;
//   min-width: 0 !important;
//   width: 0 !important;
// }

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #265ba6 !important;
  border-radius: 0 !important;
}

.ant-menu-item-selected {
  background: #265ba6 !important;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  padding: 10px !important;
}

.active {
  font-weight: bold;
}

.text-line::after {
  content: "";
  display: block;
  width: 4%;
  padding-top: 3px;
  border-bottom: 4px solid #265ba6;
}
.text-line-new::after {
  content: "";
  display: block;
  width: 4%;
  padding-top: 3px;
  border-bottom: 4px solid #265ba6;
}

.ant-btn:not(.add-time-button),
.ant-btn-default:not(.add-time-button) {
  background: #265ba6;
  border: none;
  color: white;
  min-width: 120px;
}

.plus-btn,
.plus-btn:hover {
  width: 45px !important;
  min-width: 50px !important;
  border-radius: 5px !important;
}

.ant-btn:hover:not(.add-time-button, .normal-btn, .search-btn, .plus-btn),
.ant-btn:focus:not(.add-time-button, .normal-btn, .search-btn, .plus-btn),
.ant-btn-default:hover:not(
    .add-time-button,
    .normal-btn,
    .search-btn,
    .plus-btn
  ),
.ant-btn-default:focus:not(
    .add-time-button,
    .normal-btn,
    .search-btn,
    .plus-btn
  ) {
  background: #265ba6 !important;
  border: none !important;
  color: white !important;
  min-width: 120px;
}
.normal-btn {
  box-shadow: none !important;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  outline: none;
  min-width: auto !important;
  border: none;
}
.normal-btn:focus-visible {
  outline: none !important;
}
.ant-input-number-input {
  // border-color: #727272 !important;
  border: none !important;
  border-radius: 5px !important;
}

.ant-input,
.ant-picker,
.ant-input-password,
// .ant-select,
.ant-input-affix-wrapper:not(.custom-dashboard > .ant-input-affix-wrapper) {
  border: none;
  box-shadow: none;
  background-color: #f8f8f8;
  border-radius: 5px !important;
  padding: 10px;
}

.ant-input:hover,
.ant-input:focus,
.ant-picker:hover,
.ant-input-number-input,
.ant-picker:focus,
.ant-picker-focused:focus,
.ant-picker-focused:focus,
.ant-input-password:hover,
.ant-input-password:focus,
.ant-select:hover,
.ant-select:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-color: none !important;
  background-color: #f8f8f8;
  box-shadow: none !important;
  border-radius: 5px !important;
}

.ant-select-selector {
  border: none !important;
  height: 43px;
  background-color: #f8f8f8 !important;
}

.ant-select-selector:hover,
.ant-select-selector:focus-within {
  border: none !important;
  box-shadow: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #265ba6 !important;
  border-color: #265ba6 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #265ba6 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #265ba6 !important;
}

.ant-switch-checked {
  background-color: #265ba6 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-button-white {
  background-color: transparent !important;
  border: 1px solid black !important;
  color: black;
  width: 150px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-button {
  width: 150px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-list {
  iframe {
    width: 100%;
    height: 100vh;
  }

  .ant-list-item-meta {
    align-items: center !important;
  }
}

//* all label remove padding *//
.ant-form-item-label {
  padding: 0px !important;
}

.custom-button {
  background: #265ba6;
  border: 1px solid #265ba6;
  color: white;
  min-width: fit-content !important;
}

.custom-button:hover,
.custom-button:focus {
  background: #265ba6 !important;
  border-color: #265ba6 !important;
  color: white !important;
}

.spacer {
  margin-top: 15px;
}

#overflow-menu {
  // max-height: calc(100vh - 370px);
  max-height: calc(100vh - 250px);
  overflow: auto;
}

/* Custom CSS scrollbar with 1px border */
#overflow-menu::-webkit-scrollbar {
  width: 10px;
  /* set the width of the scrollbar */
}

#overflow-menu::-webkit-scrollbar-track {
  background-color: transparent;
  /* set the background color of the scrollbar track */
}

#overflow-menu::-webkit-scrollbar-thumb {
  background-color: #265ba6;
  /* set the background color of the scrollbar thumb */
  border-radius: 5px;
}

#overflow-menu::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* set the background color of the scrollbar thumb on hover */
}
.main-card {
  .ant-card-head {
    background-color: #265ba6;
    padding: 8px 15px !important;

    .ant-card-head-title {
      padding: 7px 0;
    }
  }
}

.no-padding {
  .ant-card-head {
    background-color: #265ba6;
    padding: 0px 15px !important;
  }
}

.bg-white-drop:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
// .custom-dropdown-render .ant-dropdown {
//   // min-width: 150px !important;
// }
.bg-white-drop .ant-btn:focus-visible {
  box-shadow: none !important;
  outline: none !important;
}
.bg-white-drop {
  // width: 300px !important;
  width: auto;
  text-align: left;
  margin-right: auto !important;
  margin: 0 10px;

  .ant-dropdown-trigger {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .ant-btn,
  .ant-btn:hover,
  .ant-btn:focus {
    height: 40px;
    background-color: white !important;
    border-radius: 5px;
    min-width: auto;
    font-weight: 700;
    color: black !important;
  }
}

.bg-white-btn,
.bg-white-btn:hover,
.bg-white-btn:focus {
  background-color: white !important;
  border-radius: 5px;
  font-weight: 700;
  color: black !important;
}

.ant-steps-icon-dot {
  background-color: #265ba6;
}

// .ant-form-item
//   .ant-form-item-label
//   > label.ant-form-item-required:not(
//     .ant-form-item-required-mark-optional
//   )::before {
//   display: none;
// }
// .ant-form-item .ant-form-item-label > label::after {
//   visibility: visible !important;
//   content: "*";
//   color: #e42126;
// }
.search-btn {
  .ant-input-affix-wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.search-btn {
  min-width: 80px !important;
}

// .search-btn {
//   .ant-input-affix-wrapper {
//     background: rgba(255, 255, 255, 0.2);

//     .ant-input{
//       background-color: transparent;
//     }
//   }
// }
.search-input {
  .ant-input-affix-wrapper {
    background-color: rgba(255, 255, 255, 0.2) !important;
    .ant-input,
    .ant-input::placeholder {
      color: white !important;
      background-color: transparent;
      font-size: 15px;
    }
  }
  .ant-btn {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 44px;
    background-color: rgba(255, 255, 255, 0.5) !important;
    min-width: 80px !important;
    box-shadow: none;
    outline: none !important;
  }
  .ant-btn:hover {
    height: 44px;
    background-color: rgba(255, 255, 255, 0.5) !important;
    min-width: 80px !important;
  }
}
.ant-table-sticky-scroll {
  display: none !important;
}

// ticket status hover
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after {
  border: none !important;
}

.ant-layout-header {
  line-height: 0;
  padding: 16px !important;
}

// view model
.popUp {
  min-width: 800px;
}
.popUp.ant-modal .ant-modal-content {
  padding: 0%;
}
.popUp.ant-modal .ant-modal-title {
  background: #265ba6;
  padding: 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
  font-size: 20px;
  font-weight: 700;
}
.popUp.ant-modal .ant-modal-header {
  margin-bottom: 0px;
}
.popUp.ant-modal .ant-modal-close {
  border: 2px solid white;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.popUp.ant-modal .ant-modal-close:hover {
  color: white;
}
.popUp.ant-modal .ant-modal-body {
  padding: 20px;
  background: #f8f8f8;
  border-radius: 10px;
}
// .ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-label{
//   border: 5px solid #FFFFFF
// }
.ant-descriptions.ant-descriptions-bordered .ant-descriptions-view {
  border: none;
}
.ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-label {
  color: #000000;
  font-weight: 700;
  font-size: 16px;
}
.ant-descriptions.ant-descriptions-bordered
  .ant-descriptions-item-content:last-child {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}
.ant-descriptions-item-content {
  span {
    div {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

//user select and search btn
.custom-form {
  align-items: center;

  .user {
    min-height: 40px !important;
    .ant-select-selector {
      height: 40px !important;
      display: flex;
      align-items: center;
      background: #ffffff33 !important;
    }
    .ant-select-selection-placeholder {
      color: white;
    }
  }
  .ant-select:hover {
    background: transparent !important;
  }
  .ant-select .ant-select-arrow {
    color: white;
  }
}

.ant-table-cell {
  text-align: center !important;
}

.project-select {
  .ant-select-selector {
    min-height: 40px !important;
    height: auto !important;
  }
}
//  .ant-image .ant-image-mask .ant-image-mask-info{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: visible;
// }

// .custom-drop {
//   .ant-dropdown {
//     // min-width: 100px !important;
//     // width: 100px !important;
//   }
// }

.rate-select {
  .ant-select-selector {
    width: 120px !important;
  }
}

.ant-upload .ant-upload-select {
  border: 0.5px solid rgba(22, 32, 56, 0.5) !important;
}
.ant-btn-text:not(.not-white),
.ant-btn-text:hover:not(.not-white) {
  background-color: transparent !important;
  max-width: 30px !important;
  min-width: 30px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: none;
}
.ant-upload-picture-card-wrapper {
  border-color: rgba(22, 32, 56, 0.5) !important;
}
.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:hover {
  border-color: #e8e8e8 !important;
}

// ticket header responshiv
// @media (min-width: 1600px){
//   :where(.css-dev-only-do-not-override-1jr9qlj).ant-col-xxl-7 {
//     padding: 0px !important;
//     text-align: end !important;
//   }
// }
.hello,
.ant-descriptions-view,
.table,
.tbody .ant-descriptions-item:nth-child(4),
.ant-descriptions-item-container {
  display: block !important;
}

.tox-tinymce {
  margin: 18px 0;
}

.custom-disabled {
  background-color: #265ba6 !important;
  color: white !important;
}

.hello {
  .ant-descriptions-item-label {
    font-weight: 700;
  }
}

.not-white {
  color: white !important;
}

/* Define the default color for icons */
.opacity-75 {
  // font-size: 20px;
  color: black; /* Default icon color */
}

/* Define the color for icons when hovered */
.opacity-75.hovered {
  color: red; /* Icon color when hovered */
}
.link-tag {
  color: black;
  // font-size: 15px !important;
}
.link-tag:hover {
  color: black;
}

// send resolution
.custom-modal {
  min-width: 630px !important;
}

.custom-dragger {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: start;

  .ant-upload-drag {
    width: 100px !important;
    height: 100px !important;
  }
}

.chart-container canvas {
  /* Apply the cursor pointer style to the canvas */
  cursor: pointer !important;
}

.custom-dragger {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: start;

  .ant-upload-drag {
    width: 100px;
    height: 100px;
  }
}

.drag {
  padding: 0;
  border: 1px solid #111;
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
}

.listItem {
  font-family: sans-serif;
  background-color: #fff;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #efefef;
  user-select: none;
  color: #333;
  font-weight: 400;
}

.hello {
  background-color: #efefef;
}
.report-card {
  .ant-card-head-title {
    color: white !important;
    font-size: 18px;
  }
}

.custom-desccription {
  width: 520px;
  overflow: auto;
}
@media only screen and (max-width: 1300px) {
  .custom-desccription {
    width: 260px !important;
    overflow: auto;
  }
}
@media only screen and (min-width: 1300px) {
  .custom-desccription {
    width: 300px !important;
    overflow: auto;
  }
}
@media only screen and (min-width: 1400px) {
  .custom-desccription {
    width: 350px !important;
    overflow: auto;
  }
}
@media only screen and (min-width: 1600px) {
  .custom-desccription {
    width: 400px !important;
    overflow: auto;
  }
}
@media only screen and (min-width: 1700px) {
  .custom-desccription {
    width: 450px !important;
    overflow: auto;
  }
}
@media only screen and (min-width: 1800px) {
  .custom-desccription {
    width: 520px !important;
    overflow: auto;
  }
}

.custom-desccription::-webkit-scrollbar {
  width: 3px; /* Width of the vertical scrollbar */
  height: 3px;
}

.custom-desccription::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.custom-desccription::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 5px; /* Rounded corners */
}

.custom-desccription::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

.custom-resolution::-webkit-scrollbar {
  width: 3px; /* Width of the vertical scrollbar */
  height: 3px;
}

.custom-resolution::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.custom-resolution::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 5px; /* Rounded corners */
}

.custom-resolution::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

// taicket table row color according status
.status-blue {
  background-color: #e6f4ff !important; /* or any other style you want */
}

.status-orange {
  background-color: #fff7e6 !important; /* or any other style you want */
}

.status-warning {
  background-color: #fffbe6 !important; /* or any other style you want */
}
.status-purple {
  background-color: #f9f0ff !important; /* or any other style you want */
}
.status-magenta {
  background-color: #fff0f6 !important; /* or any other style you want */
}

.status-green {
  background-color: #f6ffed !important; /* or any other style you want */
}
.delete-red {
  color: red;
}

.ticket-table {
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none !important;
  }
  .ant-table-tbody > tr > td.ant-table-cell-fix-right {
    background-color: white !important;
  }

  // .ant-table-cell .ant-table-cell-fix-right .ant-table-cell-fix-right-first {
  //   .ant-table-cell
  //     .ant-table-cell-fix-right
  //     .ant-table-cell-fix-right-first:hover {
  //     background: white !important;
  //   }
  // .ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell-fix-right {
  //   // background: #FAFAFA  !important;
  //   background: white !important;
  //   z-index:999 !important;
  //   position: relative !important;
  // }
}
// }

/* Add this to your CSS file or style section */
.border-true {
  border: 1px solid rgba(145, 157, 169, 0.5);
  box-shadow: none !important;
  outline: none !important;
}

.border-true:hover,
.border-true:focus {
  box-shadow: none !important;
  border: 1px solid rgba(145, 157, 169, 0.5);
  outline: none !important;
}
.width {
  //width: 200px !important;
  padding: 5px 0;
  background-color: #f8f8f8;
}

.ant-btn:focus-visible {
  outline: none !important;
}

// project log css
.project-log-empty-card {
  height: 130px !important;
}

// remove red color of text in mobile input
.ant-input-out-of-range {
  color: black;
}
