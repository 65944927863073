.top-card .ant-card-body {
  min-height: 100px !important;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05) !important;
}
.top-card .ant-card-head {
  background: #265ba6 !important;
  border-radius: 5px 5px 0px 0px !important;
  color: white !important;
  text-align: center !important;
}
.center-card .ant-card-body {
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05) !important;
}

.table-row {
  .ant-table-cell {
    padding: 11px 0 !important;
  }
}

// .fragment{
.ant-list-item {
  padding: 0 !important;
  padding-bottom: 12px !important;
}
.ant-list-item-meta-avatar {
  margin: auto;
}
.ant-avatar-circle {
  margin-top: 10px;
}
.modal-Winner {
  .ant-modal-close {
    display: none;
  }
}
.detail-page {
  padding: 0 10px;
  .ant-descriptions-item-label {
    width: 450px;
    font-size: 16px;
  }
  .ant-descriptions-item-content {
    font-size: 16px;
    font-weight: 600;
  }
}

.trasperent {
  .ant-form-item {
    color: transparent;
  }
}

.block {
  .ant-modal-close {
    display: block;
  }
}

.center-card {
  .ant-select-tree-checkbox-inner {
    display: none !important;
  }
}

.hidden-label {
  .ant-form-item-label {
    opacity: 0;
  }
}

.ant-table-sticky-scroll {
  display: none !important ;
}

.for-height {
  min-height: 506px !important;

  .ant-card-body {
    box-shadow: none !important;
  }
}

.custom-dropdown .ant-dropdown {
  width: 250px !important;
}

.custom-bg-trasparent,
.custom-bg-trasparent:hover,
.custom-bg-trasparent:focus {
  color: black !important;
  border: 1px solid grey !important;
  background: transparent !important;
}

// .log-modal .ant-modal-body {
//   max-height: 500px !important;
//   overflow-y: scroll !important;
// }

.log-modal {
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .ant-modal-body {
    max-height: 500px;
    overflow-y: auto;
  }
}
.log-modal .ant-steps::-webkit-scrollbar {
  display: none;
}
#dropw .custom-select {
  width: 128px !important;
  border-radius: 5px;
  color: black;
  //padding: 2px;
  border: 0.5px solid #000;
  background: #f8f8f8 !important;
}

.custom-select::placeholder {
  color: black;
}
.custom-slect {
  .ant-input-affix-wrapper {
    background: rgba(255, 255, 255, 0.2) !important;
    max-width: 185px !important;

    .ant-input,
    .ant-input::placeholder {
      background-color: transparent;
      color: white;
    }
  }

  .range-date {
    max-width: 195px;
    color: white !important;
    background-color: rgba(255, 255, 255, 0.2) !important;

    .ant-picker-suffix {
      color: white;
    }
    .ant-picker-separator {
      color: white;
    }

    input,
    input::placeholder {
      color: white !important;
    }

    .ant-picker-clear {
      background-color: transparent !important;
      color: white;
    }
  }

  .range-date::placeholder {
    color: white !important;
  }

  .dashboard-range {
    background-color: white !important;
    border: 0.5px solid grey !important;
  }

  .ant-select-arro {
    background-color: transparent !important;
    color: white !important;
  }
  .ant-select-selection-search-input {
    background-color: transparent;
    color: white;
  }
  .ant-select-selector {
    width: 120px !important;
    color: white !important;
    // background: rgba(255, 255, 255, 0.2) !important;
  }

  .ant-select-clear {
    background: transparent !important;
  }
}

.ant-picker-panel-container .ant-picker-panel-layout {
  flex-direction: row-reverse !important;
}

.search-btn,
.search-btn:hover,
.search-btn:focus {
  background-color: #fff !important;
  color: black !important;
}

.popUp {
  .ant-modal-close-x {
    line-height: 0 !important;
  }
}
.ant-popover-inner-content {
  max-width: 300px !important;
  max-height: 200px !important;
  overflow-y: auto;
}
.rate-select .ant-select-selector {
  color: white !important;
}

// .severity .ant-select-selector{
//   width: 110px !important;
// }

.breadcumb-link,
.breadcumb-link:hover {
  // background-color: transparent !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-weight: 600;
  background-color: transparent !important;
}

.pb-list {
  padding-bottom: 0 !important;
  padding: 8px 0 !important;
}

/* Custom CSS scrollbar with 1px border */
.webkit-dash::-webkit-scrollbar {
  width: 10px;
  /* set the width of the scrollbar */
}

.webkit-dash::-webkit-scrollbar-track {
  background-color: transparent;
  /* set the background color of the scrollbar track */
}

.webkit-dash::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  /* set the background color of the scrollbar thumb */
  border-radius: 5px;
}

.webkit-dash::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.task-home-card {
  .ant-card-head {
    background-color: transparent !important;
    text-align: left !important;
    color: black !important;
  }
}

.mbb-0 {
  .ant-list-item-meta {
    margin: 0 !important;
  }
}

#dash-task-card .ant-card-body {
  padding: 10px;
  box-shadow: none !important;
}
.mb-00 {
  margin-bottom: 0 !important;
}

#dash-task-card .ant-card-body {
  padding: 0;
}

.dashboard-task {
  .ant-card-body {
    padding: 10px 15px !important;
    height: auto !important;
    min-height: auto !important ;
  }
}

.ant-empty {
  height: 415px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard-card {
  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

