/*Main Lyaout Start*/
#main-layout .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#main-layout .trigger:hover {
  color: #265ba6;
}

#main-layout .logo {
  height: auto;
  margin: auto;
  width: 80px;
}
#main-layout .ant-layout {
  background: #f1f5f9 !important;
}
#main-layout .ant-layout-header {
  background: #ffffff !important;
}
#content-full {
  // height: auto !important;
  height: 100vh !important
  ;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*Main Lyaout End*/
/*Sidebar Lyaout start*/
.sidebar {
  // overflow: auto;
  // height: 100vh;
  // position: fixed;
  // left: 0;
  // top: 0;
  // bottom: 0;
  //min-width: 250px !important;
  //max-width: 250px !important;
  //width: 250px !important;
  .admin-icon {
    border-radius: 50%;
    border: 4px solid #e70736;
    margin: 10px 0;
  }
}
.site-layout > .content-area {
  margin-left: 250px !important;
}

.ant-layout-sider {
  background-color: #fff !important;
}

.white-image {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.black-image {
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}
/*Sidebar Lyaout End*/

.user-table {
  .ant-table-cell {
    // padding: 20px 10px !important;
    padding: 10px 8px !important;
  }
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #e2eeff;
}

.ticket-table {
  .ant-table-cell {
    // padding: 15px 10px !important;
    padding: 4px 8px !important;
  }
}

// .ant-image-preview-img {
//   margin: auto;
// }

// .ant-image-preview-img-wrapper::before {
//   display: inline !important;
// }

.ant-table-cell {
  padding: 6px 8px !important;
}

.ant-table-thead .ant-table-cell {
  padding: 10px 10px !important;
}
.ant-image-preview-img {
  display: inline !important;
}
