/*Login Page start*/
.login-card {
  .ant-card-head {
    background-color: #0f172a !important;
    text-align: center;
  }
  .row-card {
    padding: 10px;
  }
}

.user-detail {
  .text-line::after {
    width: 11% !important;
  }
  .ant-card-head-title {
    padding: 0 !important;

    .ant-typography {
      margin: 15px 10px;
    }
  }
  .padding-card {
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

    .ant-card-body {
      padding: 48px !important;
    }
  }
  .center-elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ant-typography {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  .big-card {
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

    .text-line::after {
        width: 5% !important;
      }
      .text-line-new::after {
        width: 40% !important;
      }

    .ant-list {
      margin: 5px 0;
    }
    .ant-card-body {
      padding: 20px 30px;
      padding-bottom: 30px;

      .ant-list-item {
        padding: 10px 20px !important;
      }

      .ant-list-item-meta-title {
        color: rgba(114, 114, 114, 1);
        margin: 0px 0;
        font-size: 16px;
      }
      .ant-list-item-meta-description {
        color: black;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

/*Login Page End*/
