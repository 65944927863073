#time-card {
  box-shadow: none !important;
  .ant-card-body {
    padding: 2px 1px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-card-body::before {
    display: block !important;
    content: none;
  }
}

.time-modal {
  .ant-modal-footer button:first-child {
    display: none;
  }
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      padding: 20px;
    }
    .ant-modal-footer {
      text-align: left;
      // border-top: 0.5px solid rgb(240, 231, 231);
      box-shadow: 0px 1px 05px rgba(0, 0, 0, 0.2);

      padding: 10px;
    }
  }

  ::-webkit-scrollbar {
    width: 0;
  }
  .ant-modal-close {
    position: absolute;
    top: 0px !important;
    right: 5px;
    // margin: 10px;
  }

  .ant-modal-close:hover {
    background-color: transparent !important;
  }
}
.add-time-button,
.add-time-button:hover {
  background-color: transparent !important;
  color: black !important;
  border-color: grey !important;
}

.border-bottom-0 {
  box-shadow: none !important;
  border: 0.5px solid grey !important;
  .ant-card-body {
    padding: 0 1px;
  }
  .ant-card-head {
    border-bottom: 0 !important;
    padding: 0 !important
    ;
  }

  // .ant-card-head-wrapper {
  //   padding: 0 9px;
  // }
}
.hover-text-blackked,
.hover-text-blackked:hover {
  color: black !important;
  background-color: transparent !important;
  box-shadow: none !important ;
}
.time-errror {
  .ant-form-item-explain-error {
    position: relative;
    bottom: 0;
    left: 2px;
    font-weight: 600;
  }
}
.text-left-select {
  background-color: transparent !important
  ;
  .ant-select-selector,
  .ant-select-selector:hover,
  .ant-select-selector:focus {
    text-align: left;
    background-color: transparent !important;
  }
}

.estimating-card {
  // position: fixed;
  // width: 42%;
  .ant-card-body {
    padding: 24px 35px !important;
  }
}

.labelForForm {
  .ant-form-item-label > label {
    font-size: larger;
  }
}
.empty-data {
  .ant-empty {
    height: 100px !important;
  }
}

.anticon-check-circle > svg:hover {
  color: green;
}
